<template>
	<div>
		<div class="generic-title">Privacy Policy</div>
		<div class="legal-content">
			<p>Fine Grounds, LLC and its affiliates, (“Fine Grounds”, “we”, “us” and “our”) is committed to guarding your privacy and the protection of your information. We actively maintain the security of our servers where your information is stored. To better protect your privacy we provide this notice explaining our online information practices and the choices you can make about the way your information is collected and used. This notice applies to all information collected or submitted on finegrounds.app or any other website owned, operated, licensed, or controlled by Fine Grounds, and any associated software, website, web widgets, feeds and applications for third-party websites and services, and any other mobile or web services or applications owned, controlled, or offered by Fine Grounds (collectively, the “Service”).</p>
			<p>This Privacy Policy is incorporated into and is subject to the Fine Grounds Terms of Service. Your use of the Service and any personal information you provide on the Service remains subject to the Fine Grounds Terms of Service. Any capitalized terms not defined in this Privacy Policy shall have the meanings ascribed to them in such Terms of Service.</p>
			<p><strong>What is Personal Information?</strong></p>
			<p>Personal information is information that specifically identifies an individual, such as a name, address, telephone number, email address, or other account number, and information about that individual’s activities when directly linked to that person, such as information about his or her use of the Service. Personal information also includes demographic information, such as date of birth, gender, geographic location and preferences when such information is linked to other personal information that identifies you. Personal information does not include “aggregate” information. Aggregate information is data we collect about a group or category of products, services or customers, from which individual customer identities have been removed. In other words, information about how you use a service may be collected and combined with information about how others use the same service. Aggregate data helps us and our third party advertisers understand trends and our users’ needs so that we can better consider new features or otherwise tailor the Service for users like you.</p>
			<p><strong>Personal Information We Collect and How We Use It</strong></p>
			<p>We collect personal information in a number of ways when you visit the Service. For example, we collect personal information when you: (1) register and set up an account, (2) participate in an Fine Grounds course, (3) interact on our forums or networking sites or (4) contact us by email for any reason. When you visit the Service, some information is also automatically collected, such as your computer’s or mobile device’s Internet Protocol (IP) address, your computer’s or mobile device’s operating system, the browser type, the address of a referring website, and the time and date of your visit and interactions with the Service. We treat this information as personal information if we combine it with or link it to any of the identifying information mentioned above. Furthermore, if you make any purchases through the Service, billing and credit card information may be required. All such credit card transactions are fully encrypted and your billing and credit card information is collected and stored by us or a trusted third party. Any third party credit card vendor will be prohibited from using this information for any purpose other than storing the credit card.</p>
			<p>While most of the information we collect is provided voluntarily by you, some of the information we collect may be collected automatically by use of a “cookie.” Cookies are small text files that reside on your computer and identify you as a unique user. Cookies allow us to, among other things, measure activity on our website and to personalize your experience with the Service. For instance, cookies may enable us to remember your viewing preferences without requiring you to re-type a user name and password. Cookies may also allow us to monitor your progress in a course or track your status when ordering products from us. If you choose, you can set your browser to reject cookies or you can manually delete individual or all of the cookies on your computer by following your browser’s help file directions. However, if your browser is set to reject cookies or you manually delete cookies, you may have some trouble accessing and using some of the pages and features on our Service. Please see the discussion below regarding Web Analytics for more opt-out information.</p>
			<p><strong>Using and Sharing of Your Information</strong></p>
			<p>We use the information that we collect to complete a contact or request, to provide the Service, to better understand your needs, to provide to Fine Grounds marketing, to improve our products and services, and to contact you.</p>
			<p>We are committed to protecting your personal information, and will not disclose your personal information except as set forth herein. We may share your information with subcontractors that provide us with services. These services may include, among other things, helping us provide the Fine Grounds courses, helping to provide services that you request, helping to create or maintain our databases, helping to research and analyze the people who request services or information from us, helping to provide marketing or solicitation materials for our products or services, and/or helping to process payment card information. You may choose to submit information as part of a course or online community offered by Fine Grounds. Any information you choose to post, including personally identifiable information, may be available generally to the public, or to other members of the course or online community.</p>
			<p>In addition, we may disclose personal information in the good faith belief that we are lawfully authorized to do so, or that doing so is reasonably necessary to comply with legal process or authorities, respond to any claims, or to protect the rights, property or personal safety of Fine Grounds, our users, our employees or the public. Information about our users, including personal information, may be disclosed or transferred as part of, or during negotiations of, any merger, sale of company assets or acquisition.</p>
			<p><strong>Security</strong></p>
			<p>We have put into place reasonable security measures in an effort to protect personal information while it is under our control. Your account information is accessible online only through the use of a password. To protect the confidentiality of personal information, you must keep your password confidential and not disclose it to any other person. You are responsible for all uses of the Service by any person using your password. Please advise us immediately if you believe your password has been misused.</p>
			<p><strong>Control of Personal Information</strong></p>
			<p>We give you the opportunity to opt-out of receiving communications from us and to view, correct or remove your personal information.</p>
			<p>If you want to view, correct or remove your personal information, some information may be accessed and changed or removed online or by contacting us at: <a href="mailto:info@finegrounds.app" data-ytta-id="-">info@finegrounds.app</a>. If you have received unwanted, unsolicited email sent via this system or purporting to be sent via this system, please forward a copy of that email with your comments to <a href="mailto:info@finegrounds.app" data-ytta-id="-">info@finegrounds.app</a>. We will promptly take action.</p>
			<p><strong>Web Analytics</strong></p>
			<p>We use Google Analytics to analyze usage and traffic to this website. The information we collect is used to learn how our clients and users can be met with more useful, timely, and appropriate design and content.  You can further review what data is collected by reviewing the Google Privacy Policy.</p>
			<p><strong>Opt Out of Analytics</strong></p>
			<p>If you want to opt out of being part of the analytics we collect, simply follow these instructions:</p>
			<p>To opt out of Google Analytics, please <a href="https://tools.google.com/dlpage/gaoptout/" target="_blank" data-ytta-id="-">follow this link</a>.<br></p>
			<p><strong>Policy Changes</strong></p>
			<p>We reserve the right to change this Policy at any time. We may use personal information for new, unanticipated uses not previously disclosed in this privacy policy. If our privacy practices change we will post the policy changes to the Service and notify you by indicating the date the privacy policy was last updated. We will also provide you with the ability to opt out of these new uses. We encourage you to check our website periodically if you are concerned about how your information is used.<br></p>
		</div>
	</div>
</template>

<script>

export default {

	name: 'PrivacyPolicyPage',

};

</script>
